.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.heading {
  color: black;
}

.subTitle {
  color: #868e96;
}
.react-chatbot-kit-chat-container {
  width: 90% !important; /* By default, use 90% of the screen width */
  margin-left: auto !important;
  margin-right: 0 !important;
}

/* When the screen width is 444px or more, use 400px width */
@media screen and (min-width: 444px) {
  .react-chatbot-kit-chat-container {
    width: 400px !important;
  }
}

/* Adjust the inner container if needed */
.react-chatbot-kit-chat-inner-container {
  width: auto;
  max-width: 100%; /* This ensures it fills the parent container */
}

.react-chatbot-kit-chat-bot-message {
  margin-left: 0px !important;
  /* set variable width */
  width: auto !important;
  max-width: 90% !important;
}

/* Animations */
@keyframes fade_down {
  0% {
    opacity: 0.01;
    transform: translateY(-20px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}

@keyframes fade_up {
  0% {
    opacity: 0.01;
    transform: translateY(20px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
    transition: opacity 300ms cubic-bezier(0.645, 0.045, 0.355, 1),
      transform 300ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
